import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import AllProjects from "../components/projects/allProjects";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/projects.css";

const Projects = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const currentSEO = SEO.find((item) => item.page === "projects");

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Projects | ${INFO.main.title}`}</title>
				<meta name="description" content={currentSEO.description} />
				<meta
					name="keywords"
					content={currentSEO.keywords.join(", ")}
				/>
			</Helmet>

			<div className="page-content">
				<NavBar active="projects" />
				<div className="content-wrapper">
					<div className="projects-logo-container">
						<div className="projects-logo">
							<Logo width={46} />
						</div>
					</div>
					<div className="projects-container">
						<div className="title projects-title">
						Showcase of Technical Expertise: My Portfolio of Projects
						</div>

						<div className="subtitle projects-subtitle">
						Welcome to the hub of my ingenuity—a collection of projects that encapsulate my
						 journey through the worlds of software engineering, data analysis, machine learning,
						  and more. This portfolio serves as a testament to my commitment to continual learning,
						   problem-solving, and pushing the boundaries of what technology can achieve.
						    Each project reveals a facet of my skills, from data structures to algorithmic
							 design, all the way to cutting-edge AI implementations. Scroll down to delve
							  into the technical complexities and the innovative solutions I've developed.
						</div>

						<div className="projects-list">
							<AllProjects />
						</div>
					</div>
					<div className="page-footer">
						<Footer />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Projects;
